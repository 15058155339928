import { StyledCol } from '@/shared/styles';
import { useThemeStore } from '@/stores/ThemeStore/ThemeStore';
import { isMobile } from 'react-device-detect';
import styled from 'styled-components';
import { ButtonBannerContainerProps } from './ButtonBanner.consts';

export const StyledSpan = styled.span`
  color: ${(props) => props.theme.sameColorText} !important;
`;

export const StyledButtonBanner = styled(StyledCol)<ButtonBannerContainerProps>`
  border: 1px solid ${(props) =>
    props.$filled ? props.theme.sameColorBG : props.theme.borderColor};
  border-radius: 24px;
  align-items: center;
  padding: 40px;

  ${(props) =>
    props.$filled
      ? `background: ${props.theme.marginsColor};`
      : !useThemeStore.getState().isDarkMode && `background: ${props.theme.sameColorBG};`} }

  ${
    isMobile &&
    `
  [data-component='contactUsDialog'] {
    width: 100%;
  }`
  };
`;

export const StyledGetStartedText = styled.div`
  text-align: center;
  font-weight: 200;
  font-size: ${isMobile ? '14' : '16'}px;
  width: ${isMobile ? '100' : '75'}%;
  color: ${(props) => props.theme.sameColorText};
`;
