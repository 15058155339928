import { StyledOrangeGradTitle } from '@/shared/styles';
import {
  StyledMainSectionsText,
  StyledSectionContainer,
  StyledTopDescription,
  StyledTrustedCompanies,
} from '../../MainPage.styles';
import { getTrustedCompaneis } from './TrustedBy.consts';
import { Icon } from '@/shared/components/Icon';
import { useThemeStore } from '@/stores/ThemeStore/ThemeStore';
import { useTranslation } from 'react-i18next';
import { THEME_ICONS } from '@/shared/consts';

export const U1TrustedBy: React.FC = () => {
  const isDarkMode = useThemeStore((state) => state.isDarkMode);
  const { t } = useTranslation();
  return (
    <StyledSectionContainer>
      <StyledTopDescription>
        <StyledOrangeGradTitle level={2}>{t('homepage.trusted.title')}</StyledOrangeGradTitle>
        <StyledMainSectionsText>{t('homepage.trusted.content')}</StyledMainSectionsText>
      </StyledTopDescription>
      <StyledTrustedCompanies>
        {getTrustedCompaneis(isDarkMode ? THEME_ICONS.WHITE : THEME_ICONS.DARK).map((comp) => (
          <Icon key={comp.src} $src={comp.src} $alt={comp.alt} $size={100} $loading="lazy" />
        ))}
      </StyledTrustedCompanies>
    </StyledSectionContainer>
  );
};
