import { StyledCol, StyledPurpleGradTitle } from '@/shared/styles';
import { StyledFeaturesContainer } from '../../LandingPages.styles';
import { Icon } from '@/shared/components/Icon';
import { isMobile } from 'react-device-detect';
import { StyledFeatureCard, StyledFeatureText, StyledFeatureTitle } from './Features.styles';
import { useTranslation } from 'react-i18next';
import { featuresImages } from './Features.consts';

export const OurFeatures: React.FC = () => {
  const { t } = useTranslation();

  return (
    <StyledCol $gap={40}>
      <StyledPurpleGradTitle level={2} $align={isMobile ? 'left' : 'center'}>
        {t('landing_page.features.title')}:
      </StyledPurpleGradTitle>
      <StyledFeaturesContainer>
        {featuresImages.map((img, i) => (
          <StyledFeatureCard key={`features${i}`}>
            <Icon $src={img} $size={32} />
            <StyledCol $gap={8}>
              <StyledFeatureTitle level={3}>
                {t(`landing_page.features.block${i + 1}.title`)}:
              </StyledFeatureTitle>
              <StyledFeatureText>
                {t(`landing_page.features.block${i + 1}.content`)}
              </StyledFeatureText>
            </StyledCol>
          </StyledFeatureCard>
        ))}
      </StyledFeaturesContainer>
    </StyledCol>
  );
};
