import { Route, Routes, useLocation } from 'react-router-dom';
import { SignIn } from './SignIn/SSO/SignIn';
import { MainPage } from './MainPage/MainPage';
import { StyledBgLayout } from './BeforeLogin.styles';
import { PAGES } from '@/shared/consts';
import { Header } from './Header/Header';
import { Footer } from './Footer/Footer';
import { MainPageFooter } from './MainPage/Footer/Footer';
import { isBrowser } from 'react-device-detect';
import { StyledBorder } from './Footer/Footer.styles';
import { lazy, Suspense, useEffect, useRef, useState } from 'react';
import { noHeaderPages, pagesWithBorderFooter, pagesWithNoFooter } from './BeforeLogin.consts';
import { TermsAndConditions } from '@/shared/pages/StaticPages/TermsAndConditions/TermsAndConditions';
import { PrivacyPolicy } from '@/shared/pages/StaticPages/PrivacyPolicy/PrivacyPolicy';
import { AccessibilityStatement } from '@/shared/pages/StaticPages/AccessibilityStatement/AccessibilityStatement';
import { AdminSignIn } from './SignIn/AdminSignIn/AdminSignIn';
import { VendorSignIn } from './SignIn/VendorSIgnIn/VendorSignIn';
import { CreatePassword } from './SignIn/CreatePassword/CreatePassword';
import { SecurityAlert } from './Errors/SecurityAlert/SecurityAlert';
import { BeforeLogin404 } from './Errors/BeforeLogin404/BeforeLogin404';
import { Loading } from '@/shared/components/Loading/Loading';
import { useLangStore } from '@/stores/LangStore/LangStore';
import { MainLandingPage } from './LandingPages/MainLandingPage/MainLandingPage';

export const BeforeLogin: React.FC = () => {
  const location = useLocation().pathname.split('/').slice(-1)[0];
  const [hasHeader, setHasHeader] = useState(true);
  const [hasFooter, setHasFooter] = useState(false);
  const [hasBorderFooter, setHasBorderFooter] = useState(false);
  const topPageRef = useRef(null);

  useEffect(() => {
    setHasHeader(!noHeaderPages.includes(location.toLocaleLowerCase()));
    setHasFooter(!pagesWithNoFooter.includes(location.toLocaleLowerCase()));
    setHasBorderFooter(pagesWithBorderFooter.includes(location.toLocaleLowerCase()));
  }, [location]);

  const AboutPageLazy = lazy(() =>
    import('./TheCompany/AboutUs/AboutUs').then((module) => ({ default: module.AboutUs }))
  );
  const OurTeamLazy = lazy(() =>
    import('./TheCompany/OurTeam/OurTeam').then((module) => ({ default: module.OurTeam }))
  );
  const FAQLazy = lazy(() =>
    import('./ThePlatform/Faq/Faq').then((module) => ({ default: module.FAQ }))
  );
  const PricingLazy = lazy(() =>
    import('./Pricing/PackagesPricing').then((module) => ({ default: module.PackagesPricing }))
  );
  const { availableLangs } = useLangStore();

  return (
    <StyledBgLayout ref={topPageRef}>
      {hasHeader && <Header />}
      <Suspense fallback={<Loading />}>
        <Routes>
          {/* lang routes */}
          {availableLangs.map((lang) => (
            <Route key={lang.code} path={`/${lang.code}`}>
              <Route path={PAGES.SHARED.DEFAULT.replace('/', '')} element={<MainPage />} />
              <Route path={PAGES.BEFORE_LOGIN.MAIN_PAGE.replace('/', '')} element={<MainPage />} />
              <Route
                path={PAGES.BEFORE_LOGIN.ADMIN_SIGN_IN.replace('/', '')}
                element={<AdminSignIn />}
              />
              <Route
                path={PAGES.BEFORE_LOGIN.VENDOR_SIGN_IN.replace('/', '')}
                element={<VendorSignIn />}
              />
              <Route
                path={PAGES.BEFORE_LOGIN.PASSWORD.replace('/', '')}
                element={<CreatePassword />}
              />
              <Route path={PAGES.BEFORE_LOGIN.SIGN_IN.replace('/', '')} element={<SignIn />} />
              <Route
                path={PAGES.BEFORE_LOGIN.SIGN_IN_INVITE.replace('/', '')}
                element={<SignIn isFromInvite={true} />}
              />
              <Route
                path={PAGES.BEFORE_LOGIN.ABOUT_US.replace('/', '')}
                element={<AboutPageLazy />}
              />
              <Route path={PAGES.BEFORE_LOGIN.FAQ.replace('/', '')} element={<FAQLazy />} />
              <Route
                path={PAGES.BEFORE_LOGIN.OUR_TEAM.replace('/', '')}
                element={<OurTeamLazy />}
              />
              <Route path={PAGES.BEFORE_LOGIN.PRICING.replace('/', '')} element={<PricingLazy />} />
              <Route
                path={PAGES.SHARED.TERMS_AND_CONDITIONS.replace('/', '')}
                element={<TermsAndConditions />}
              />
              <Route
                path={PAGES.SHARED.PRIVACY_POLICY.replace('/', '')}
                element={<PrivacyPolicy />}
              />
              <Route
                path={PAGES.SHARED.ACCESSIBILITY_STATEMENT.replace('/', '')}
                element={<AccessibilityStatement />}
              />
              <Route
                path={PAGES.BEFORE_LOGIN.LANDING_PAGE.replace('/', '')}
                element={<MainLandingPage />}
              />
              <Route path={PAGES.SHARED.NOT_FOUND.replace('/', '')} element={<BeforeLogin404 />} />
              <Route
                path={PAGES.BEFORE_LOGIN.SECURITY_ALERT.replace('/', '')}
                element={<SecurityAlert />}
              />
            </Route>
          ))}

          {/* default routes */}
          <Route path={PAGES.SHARED.DEFAULT} element={<MainPage />} />
          <Route path={PAGES.BEFORE_LOGIN.MAIN_PAGE} element={<MainPage />} />
          <Route path={PAGES.BEFORE_LOGIN.ADMIN_SIGN_IN} element={<AdminSignIn />} />
          <Route path={PAGES.BEFORE_LOGIN.VENDOR_SIGN_IN} element={<VendorSignIn />} />
          <Route path={PAGES.BEFORE_LOGIN.PASSWORD} element={<CreatePassword />} />
          <Route path={PAGES.BEFORE_LOGIN.SIGN_IN} element={<SignIn />} />
          <Route
            path={PAGES.BEFORE_LOGIN.SIGN_IN_INVITE}
            element={<SignIn isFromInvite={true} />}
          />

          <Route path={PAGES.BEFORE_LOGIN.ABOUT_US} element={<AboutPageLazy />} />
          <Route path={PAGES.BEFORE_LOGIN.FAQ} element={<FAQLazy />} />
          <Route path={PAGES.BEFORE_LOGIN.OUR_TEAM} element={<OurTeamLazy />} />
          <Route path={PAGES.BEFORE_LOGIN.PRICING} element={<PricingLazy />} />
          <Route path={PAGES.SHARED.TERMS_AND_CONDITIONS} element={<TermsAndConditions />} />
          <Route path={PAGES.SHARED.PRIVACY_POLICY} element={<PrivacyPolicy />} />
          <Route path={PAGES.SHARED.ACCESSIBILITY_STATEMENT} element={<AccessibilityStatement />} />
          <Route path={PAGES.BEFORE_LOGIN.LANDING_PAGE} element={<MainLandingPage />} />
          <Route path={PAGES.SHARED.NOT_FOUND} element={<BeforeLogin404 />} />
          <Route path={PAGES.BEFORE_LOGIN.SECURITY_ALERT} element={<SecurityAlert />} />
        </Routes>
      </Suspense>
      {hasFooter && (
        <Footer>
          {hasBorderFooter ? (
            isBrowser && <StyledBorder />
          ) : (
            <MainPageFooter topPageRef={topPageRef} />
          )}
        </Footer>
      )}
    </StyledBgLayout>
  );
};
