import { StyledButtonBanner, StyledGetStartedText } from './ButtonBanner.styles';
import { ButtonBannerProps } from './ButtonBanner.consts';

export const ButtonBanner: React.FC<ButtonBannerProps> = ({
  $title1,
  $title2,
  $content,
  $button,
  $filled,
}: ButtonBannerProps) => {
  return (
    <StyledButtonBanner $gap={16} $filled={$filled}>
      {$title1}
      {$title2 && $title2}
      <StyledGetStartedText>{$content}</StyledGetStartedText>
      {$button}
    </StyledButtonBanner>
  );
};
