import { TextProps, TitleProps } from '@/shared/props';
import { gradientTitle, StyledTitle } from '@/shared/styles';
import styled from 'styled-components';
import { isMobile } from 'react-device-detect';
import Title from 'antd/lib/typography/Title';

export const StyledMainLandingPage = styled.main`
  padding: ${isMobile ? '40px 16px' : '100px'};
  position: relative;
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: ${isMobile ? '80' : '120'}px;

  img {
    top: 0px;
    right: 0px;
  }
`;

export const StyledFeaturesContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(${isMobile ? '1' : '3'}, 1fr);
  gap: ${isMobile ? '2' : '1'}rem;
  justify-items: center;
`;

export const StyledHybridTitle = styled(StyledTitle)<{ $break?: boolean }>`
  font-size: ${(props) => props.$size ?? '44'}px !important;
  font-weight: 600 !important;
  width: fit-content;
  gap: 8px;
  ${(props) => props.$align && `align-items: ${props.$align};`}
  ${(props) =>
    props.$break &&
    `
    display: flex;
    flex-direction: column;
    `}
`;

export const StyledLPText = styled.div<TextProps & { $width?: string }>`
  font-size: ${(props) => props.fontSize ?? '16px'};
  font-weight: 400;
  color: ${(props) => props.theme.textColor};
  ${(props) => props.$width && `width: ${props.$width}`};
`;

export const StyledLandingPageFooter = styled.footer`
  display: flex;
  flex-direction: column;
  padding: 60px 25px;
  gap: 40px;
  width: 100%;
  background-color: ${(props) => props.theme.sameColorBG};
`;

export const StyledMobileAdaptiveRow = styled.div`
  display: flex;
  flex-direction: ${isMobile ? 'column' : 'row'};
  ${isMobile && `gap: 60px;`}
`;

export const StyledGradTitle = styled(Title)<TitleProps>`
  background: ${(props) => props.theme.sameColorGradient} !important;
  font-size: 16px !important;
  font-weight: 600 !important;

  margin-top: 0px !important;
  margin: 0px !important;
  ${gradientTitle};
`;
