import { StyledLandingPageFooter, StyledMobileAdaptiveRow } from '../../LandingPages.styles';
import { StyledRowOffset } from '@/shared/styles';
import { StyledDivider, StyledFooterContent } from './Footer.styles';
import { useTranslation } from 'react-i18next';
import { FooterSections } from './FooterSections';
import { User1stDetails } from './User1stDetails';

export const LandingPageFooter: React.FC = () => {
  const { t } = useTranslation();
  return (
    <StyledLandingPageFooter>
      <StyledMobileAdaptiveRow>
        <User1stDetails />
        <FooterSections />
      </StyledMobileAdaptiveRow>
      <StyledDivider />
      <StyledRowOffset>
        <StyledFooterContent>{t('landing_page.footer.all_rights_reserved')}</StyledFooterContent>
        <StyledFooterContent>{t('landing_page.footer.copyright')}</StyledFooterContent>
      </StyledRowOffset>
    </StyledLandingPageFooter>
  );
};
