import { StyledCol, StyledPurpleGradTitle } from '@/shared/styles';
import { useTranslation } from 'react-i18next';
import { useLangStore } from '@/stores/LangStore/LangStore';
import { StyledLPText } from '../../../LandingPages.styles';

export const ImportanceDescription: React.FC = () => {
  const { t } = useTranslation();
  const isRtl = useLangStore((state) => state.isRtl());
  return [...Array(3)].map((_, i) => (
    <StyledCol $gap={16} key={`importance${i}`}>
      <StyledPurpleGradTitle
        level={3}
        $size={18}
        $align={isRtl ? 'right' : 'left'}
        $width={isRtl ? 'fit-content' : '100%'}
      >
        {t(`landing_page.importance.block${i + 1}.title`)}:
      </StyledPurpleGradTitle>
      <StyledLPText fontSize={'18px'}>
        {t(`landing_page.importance.block${i + 1}.content`)}
      </StyledLPText>
    </StyledCol>
  ));
};
