import { U1Button } from '@/shared/components/Button/U1Button';
import { ContactUsModal } from '@/shared/components/Modals/ContactUsModal/ContactUsModal';
import { isMobile } from 'react-device-detect';
import { DemoModalProps } from './DemoModal.consts';

export const DemoModal: React.FC<DemoModalProps> = ({ btnText }: DemoModalProps) => {
  return (
    <ContactUsModal
      refName="contact-us-intro"
      trigger={<U1Button text={btnText} $width={isMobile ? '100%' : '167px'} />}
    />
  );
};
